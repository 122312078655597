<template>
  <div
    v-loading.fullscreen.lock="loading"
    class="cp-login"
    element-loading-text="正在跳转，请稍后"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <template v-if="localDeployment">
      <div
        class="locallogigpage"
        :style="{ 'background-image': 'url(' + loginBg.current + ')' }"
      >
        <el-image v-if="!isWgsLogin" class="imgs" :src="loginurl" fit="cover">
        </el-image>
        <div v-if="!dialogVisible" class="formboxes">
          <template v-if="!bindFlag">
            <h3
              v-for="(item, index) in systemTitle.split('|')"
              :key="'title_' + index"
              class="login-title"
            >
              {{ item }}
            </h3>
          </template>
          <el-form
            v-if="!bindFlag"
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="0px"
            class="ruleFormbox"
          >
            <template v-if="loginType">
              <div class="wechart-imgbtn" @click="loginType = 0"></div>
              <div class="wechart-code">
                <cp-wechat-code></cp-wechat-code>
              </div>
            </template>
            <template v-else-if="loginType === 0 && !wxAccreditKey">
              <div class="formtitles">
                用户登录
              </div>
              <el-form-item label="" prop="username" :error="errorMsg">
                <el-input
                  v-model.trim="ruleForm.username"
                  maxlength="11"
                  type="text"
                  class="logininputs"
                  :readonly="readonly"
                  auto-complete="off"
                  placeholder="请输入账号"
                  @focus="accountFocus"
                  @blur="checkPhone"
                  @input="
                    ruleForm.username = ruleForm.username.replace(/[^\d]/g, '')
                  "
                >
                  <i slot="prefix" class="daq-icon cp-icon">&#xe836;</i>
                </el-input>
              </el-form-item>
              <el-form-item v-if="!accountLogin" label="" prop="password11">
                <el-input
                  v-model="ruleForm.password11"
                  maxlength="20"
                  auto-complete="new-password"
                  type="password"
                  :readonly="readonly"
                  class="logininputs"
                  placeholder="请输入密码"
                  @focus="accountFocus"
                >
                  <i slot="prefix" class="daq-icon cp-icon">&#xe837;</i>
                </el-input>
              </el-form-item>
              <div v-if="!accountLogin" class="code">
                <div class="inp">
                  <el-form-item prop="code">
                    <el-input
                      v-model="ruleForm.code"
                      type="text"
                      style="height:40px"
                      maxlength="4"
                      placeholder="请输入验证码"
                      autocomplete="off"
                      @focus="focusClear"
                    >
                      <i slot="prefix" class="cp-icon">
                        &#xe85f;
                      </i>
                    </el-input>
                  </el-form-item>
                </div>
                <img
                  class="captcha"
                  :src="captcha"
                  alt="验证码"
                  @click="getCaptcha"
                />
              </div>
              <el-form-item
                v-if="accountLogin"
                label=""
                prop="verifyprop"
                class="verifyitems"
              >
                <el-input
                  v-model.trim="ruleForm.verifyprop"
                  maxlength="6"
                  class="logininputs logininputsinner"
                  placeholder="请输入验证码"
                  :readonly="readonly"
                  @focus="accountFocus"
                  @input="
                    ruleForm.verifyprop = ruleForm.verifyprop.replace(
                      /[^\d]/g,
                      ''
                    )
                  "
                >
                  <i slot="prefix" class="daq-icon cp-icon">&#xe838;</i>
                </el-input>
                <p class="yzmtips">
                  <span
                    v-if="timeNum === 60 || timeNum <= 0"
                    @click="clickTime"
                  >
                    短信验证码
                  </span>
                  <span v-else class="colorboxs">{{ timeNum }} s</span>
                </p>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  class="loginbtns"
                  :class="{ shake: isShake }"
                  @click="onSubmit"
                >
                  登录
                </el-button>
              </el-form-item>
              <el-alert
                v-if="isLogin"
                :title="errorTips"
                type="error"
                :closable="false"
                show-icon
              >
              </el-alert>
              <div class="otherstyle">
                <div class="login-box">
                  <i class="daq-icon cp-icon">&#xe835;</i>
                  <span v-if="accountLogin" @click="accountLoginClick(false)"
                    >账号密码登录</span
                  >
                  <span v-else @click="accountLoginClick(true)"
                    >短信验证码登录</span
                  >
                </div>
                <div
                  v-if="wxAccreditLoginFlag"
                  class="login-box"
                  @click="loginType = 1"
                >
                  <i class="daq-icon cp-icon">&#xeb3b;</i>
                  <span>微信扫码登录</span>
                </div>
              </div>
            </template>
          </el-form>
        </div>
      </div>
    </template>
    <template v-if="false">
      <div v-show="!loading" class="login-info">
        <div class="message-error">
          <i class="el-message__icon el-icon-error"></i>
          <p class="content">
            {{ message }}，<span>{{ seconds }}</span
            >秒后将跳转登录页
          </p>
        </div>
        <el-button @click="gotoLoginPage">
          立即跳转
        </el-button>
      </div>
    </template>
    <cp-force-password
      v-if="isForceFassword"
      :changepwd="isForceFassword"
      :mobile="forcePhone"
      @closeDiag="isForceFassword = false"
    ></cp-force-password>
    <el-dialog
      :visible.sync="dialogVisible"
      :modal="true"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :show-close="false"
      custom-class="cp-login-dialog"
      width="800px"
    >
      <div v-if="dialogVisible" slot="title" class="dialogTitle">
        检测到<font class="phone"> {{ mobileFilter(ruleForm.username) }} </font
        >在系统内存在多个身份，请选取一个身份进行登录
      </div>
      <div v-if="dialogVisible" class="dialogInfo">
        <div
          v-for="item in groups"
          :key="item.id"
          class="dialogInfo-item"
          :class="{
            disable: item.status === 0,
            active: site.accountId === item.accountId && site.id === item.id
          }"
          @click="changeSite(item)"
        >
          <div class="info-box">
            <div class="header">
              <i class="cp-icon">&#xec3a;</i>
            </div>
            <div class="title">
              <p>{{ item.companyName }}</p>
              <p v-if="groupNum > 1">
                {{ item.empName }}
              </p>
            </div>
          </div>
          <div v-if="item.status === 0" class="disable-txt">
            禁用
          </div>
        </div>
        <div
          v-for="item in scenic"
          :key="item.id"
          class="dialogInfo-item"
          :class="{
            disable: item.status === 0,
            active: site.accountId === item.accountId && site.id === item.id
          }"
          @click="changeSite(item)"
        >
          <div class="info-box">
            <div class="header">
              <i class="cp-icon">&#xec39;</i>
            </div>
            <div class="title">
              <p>{{ item.scenicName }}</p>
              <p v-if="companyNum > 1">
                {{ item.empName }}
              </p>
            </div>
          </div>
          <div v-if="item.status === 0" class="disable-txt">
            禁用
          </div>
        </div>
      </div>
      <div class="dialogFooter">
        <el-button type="primary" class="btn-info" @click="changeCampany()">
          登录
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="bindFlag"
      :modal="true"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :show-close="false"
      custom-class="cp-login-dialog"
      width="500px"
    >
      <div v-if="bindFlag" slot="title" class="dialogTitle">
        当前微信用户未绑定手机号，请输入系统登录手机号绑定
      </div>
      <div v-if="bindFlag" class="dialogInfo locallogigpage">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="0px"
          class="ruleFormbox"
        >
          <el-form-item label="" prop="username" :error="errorMsg">
            <el-input
              v-model.trim="ruleForm.username"
              maxlength="11"
              type="text"
              size="large"
              class="logininputs"
              :readonly="readonly"
              auto-complete="off"
              placeholder="请输入账号"
              @focus="accountFocus"
              @blur="checkPhone"
              @input="
                ruleForm.username = ruleForm.username.replace(/[^\d]/g, '')
              "
            >
              <i slot="prefix" class="daq-icon cp-icon">&#xe836;</i>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="verifyprop" class="verifyitems">
            <el-input
              v-model.trim="ruleForm.verifyprop"
              maxlength="6"
              size="large"
              class="logininputs logininputsinner"
              placeholder="请输入验证码"
              :readonly="readonly"
              @focus="accountFocus"
              @input="
                ruleForm.verifyprop = ruleForm.verifyprop.replace(/[^\d]/g, '')
              "
            >
              <i slot="prefix" class="daq-icon cp-icon">&#xe838;</i>
            </el-input>
            <p class="yzmtips">
              <span v-if="timeNum === 60 || timeNum <= 0" @click="clickTime">
                短信验证码
              </span>
              <span v-else class="colorboxs">{{ timeNum }} s</span>
            </p>
          </el-form-item>
        </el-form>
      </div>
      <div class="dialogFooter">
        <el-button type="primary" class="btn-info" @click="bindWxUserName()">
          立即绑定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import comm from '@/utils/comm';
import { mapGetters } from "vuex";
// import config from '@/config';
import loginUrl from "../../assets/images/localloginbgleft.png";
import { ajax } from "@/utils";
import CpWechatCode from "./components/wechat-code.vue";
import CpForcePassword from "./components/force-password.vue";
import { MessageBox } from "element-ui";
export default {
  name: "Login",
  components: {
    CpWechatCode,
    CpForcePassword
  },
  data() {
    return {
      forcePhone: "",
      // 应产品要求，无需浏览器记忆功能
      readonly: true,
      dialogVisible: false,
      groups: [],
      scenic: [],
      tempLicense: "",
      loginurl: loginUrl,
      loading: true,
      // 认证码
      authCode: "",
      // 跳转倒计时 秒
      seconds: 5,
      // 提示信息
      message: "",
      // 计时器
      timer: 0,
      encryption: "",
      ruleForm: {
        username: "",
        password11: "",
        verifyprop: "",
        captcha: "",
        code: "",
        captchaRandomCode: ""
      },
      loginType: 0,
      rules: {
        username: [
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (!value) {
                callback(new Error("账号不能为空"));
                return;
              } else if (value && value.length !== 11) {
                callback(new Error("手机号码格式不正确"));
                return;
              } else if (this.bindFlag && value && value.length === 11) {
                let ispost = await this.wxValidPhone(value);
                if (!ispost) {
                  callback(new Error("手机号不是系统用户，请联系管理员"));
                  return;
                }
              }
              callback();
            },
            trigger: ["blur"]
          }
        ],
        password11: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          }
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur"
          }
        ],
        verifyprop: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur", "change"]
          },
          {
            min: 6,
            max: 6,
            message: "请输入6位数的验证码",
            trigger: "blur"
          }
        ]
      },
      localDeployment: false,
      // 武功山登录背景
      isWgsLogin: false,
      accountLogin: false,
      timeNum: 60,
      timers: null,
      errorMsg: "",
      systemTitle: "",
      loginBg: {
        common: require("../../assets/images/localloginbg.png"),
        wgs: require("../../assets/images/wgs_bg.jpg"),
        current: ""
      },
      site: {},
      captcha: "",
      captchaIsFlag: true,
      errorTips: "",
      isLogin: false,
      // 按钮抖动参数
      isShake: false,
      bindFlag: false,
      wxAccreditKey: "",
      wxAccreditLoginFlag: false,
      // 强制修改密码弹窗
      isForceFassword: false
    };
  },
  computed: {
    ...mapGetters({
      code: "authCode",
      systemId: "systemId"
    }),
    groupNum() {
      return this.groups.length;
    },
    companyNum() {
      return this.scenic.length;
    }
  },
  created() {
    sessionStorage.removeItem('isthridSys');
    // 武功山登录背景
    this.isWgsLogin = Boolean(window.scenicManagementPlatformConfig.isWgsLogin);
    if (this.isWgsLogin) {
      this.loginBg.current = this.loginBg.wgs;
    } else {
      this.loginBg.current = this.loginBg.common;
    }
    if (window.scenicManagementPlatformConfig.isLocal) {
      // 本地部署
      this.localDeployment = true;
      this.getCaptcha();
    } else {
      this.localDeployment = false;
    }
    if (this.$route.query.key && this.$route.query.key.length > 0) {
      // alert('3401');
      this.authCode = this.$route.query.key;
      this.$store.commit("authCode", this.authCode);
      this.localDeployment = false;
      this.login();
    } else if (
      this.$route.query.wxAccreditKey &&
      this.$route.query.wxAccreditKey.length > 0
    ) {
      // 微信授权后处理
      this.wxAccreditKey = this.$route.query.wxAccreditKey;
      this.wxQueryBind();
    } else {
      this.getSystemName();
      this.keyupEnter();
    }
    this.getCaptcha();
    this.childPageCallBack();
  },
  beforeDestroy() {
    if (this.timers) {
      window.clearInterval(this.timers);
    }
    this.timers = null;
    this.timeNum = 0;
  },
  methods: {
    // 判断微信是否绑定appid
    async wxValidPhone(mobile) {
      const res = await this.$api.wxValidPhone({
        mobile
      });
      if (res.code === 0) {
        return res.data;
      } else {
        return false;
      }
    },
    // 判断微信是否绑定appid
    wxQueryBind() {
      const urls = "local-deploy/login/wx-qr/query-bind";
      ajax
        .request("get", urls, {
          wxAccreditKey: this.wxAccreditKey
        })
        .then(res => {
          if (res.code === 0) {
            this.bindFlag = !res.data.bindFlag;
            this.loginSiteInfo(res);
          }
        });
    },
    /**
     * 输入框获取焦点时清空提示
     */
    focusClear() {
      this.isShake = false;
      this.isLogin = false;
      this.errorTips = "";
      this.readonly = true;
    },
    accountFocus() {
      this.focusClear();
      this.readonly = false;
    },

    /**
     * 获取验证码、重置验证码
     */
    getCaptcha() {
      const that = this;
      if (!that.captchaIsFlag) {
        return;
      }
      that.captchaIsFlag = false;
      this.$api
        .getCaptcha()
        .then(res => {
          if (res.code === 0 && res.data) {
            that.captcha = `data:image/jpg;base64,${res.data.image}`;
            that.ruleForm.captchaRandomCode = res.data.randomCode;
            setTimeout(() => {
              that.captchaIsFlag = true;
            }, that.captchaTime);
          } else {
            that.$notice.operateError(
              "获取验证码",
              "验证码获取失败，请稍后再试！"
            );
          }
        })
        .catch(error => {
          console.error(error);
          that.$notice.operateError(
            "获取验证码",
            "验证码获取失败，请稍后再试！"
          );
        });
    },
    mobileFilter(tel) {
      if (!tel) {
        return tel;
      }
      return tel.replace(tel.substring(3, 7), "****");
    },
    changeSite(info) {
      this.site = info;
    },
    // 切换公司
    changeCampany() {
      if (JSON.stringify(this.site) === "{}") {
        this.$notice.operateError("登录失败", "请选择一个公司登录");
        return;
      }
      let objs = this.wxAccreditKey
        ? {
            accountId: this.site.accountId,
            companyId: this.site.id,
            wxAccreditKey: this.wxAccreditKey
          }
        : {
            accountId: this.site.accountId,
            companyId: this.site.id,
            tempLicense: this.tempLicense
          };
      objs.customBaseUrl =
        window.scenicManagementPlatformConfig.customBaseUrl || "";
      const apiName = this.wxAccreditKey
        ? "local-deploy/login/wx-qr/choose-company"
        : "local-deploy/login/choose-company";
      ajax
        .request("post", apiName, objs)
        .then(res => {
          if (res.code === 0) {
            this.$notice.operateSuccess("登录成功", "");
            window.location.href = res.data;
          } else {
            this.$notice.operateError("登录失败", res.message || "");
          }
          return false;
        })
        .catch(err => {
          this.$notice.operateError("登录失败", err.data.message || "");
          return false;
        });
    },
    // 获取系统标题
    getSystemName() {
      const urls = "local-deploy/login/config";
      ajax.request("get", urls, {}).then(res => {
        if (res.code === 0) {
          // 标题信息
          this.systemTitle = res.data.titleName;
          this.wxAccreditLoginFlag = res.data.wxAccreditLoginFlag;
          localStorage.setItem("systemTitle", this.systemTitle);
          document.title = this.systemTitle.replace(/|/g, "");
          if (this.localDeployment) {
            this.loading = false;
          }
        }
      });
    },
    // 前端校验手机号码
    checkPhone() {
      this.errorMsg = "";
      if (!this.ruleForm.username) {
        this.$nextTick(() => {
          this.errorMsg = "账号不能为空";
        });
        return;
      }
      const myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.ruleForm.username)) {
        // alert('手机号格式不正确');
        this.$nextTick(() => {
          this.errorMsg = "手机号格式不正确";
          return false;
        });
      }
    },
    // 绑定微信账号
    bindWxUserName() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$api
            .bindWxUserName({
              wxAccreditKey: this.wxAccreditKey,
              mobile: this.ruleForm.username,
              msgCode: this.ruleForm.username
            })
            .then(res => {
              if (res.code === 0) {
                // 绑定成功重新调用绑定成功判定接口
                this.loginSiteInfo(res);
              } else {
                this.$notice.operateError("账号绑定失败", res.message || "");
              }
              return;
            })
            .catch(err => {
              this.$notice.operateError("账号绑定失败", err.data.message || "");
              return;
            });
        }
      });
    },
    // 获取短信验证码
    clickTime() {
      if (!this.ruleForm.username) {
        return;
      }
      const checkphone = () => {
        let param = {
          mobile: this.ruleForm.username
        };
        // 微信绑定手机号验证码获取
        let apiName = this.bindFlag ? "getWxVertify" : "getVertify";
        this.$api[apiName](param)
          .then(res => {
            if (res.code === 0) {
              this.$notice.operateSuccess("手机验证码发送成功", "");
              if (this.timers) {
                window.clearInterval(this.timers);
              }
              this.timers = null;
              this.timeNum = 60;
              this.timers = window.setInterval(() => {
                if (this.timeNum >= 0) {
                  this.timeNum--;
                }
              }, 1000);
            } else {
              this.$notice.operateError(
                "手机验证码发送失败",
                res.message || ""
              );
            }
            return false;
          })
          .catch(err => {
            this.$notice.operateError(
              "手机验证码发送失败",
              err.data.message || ""
            );
            return false;
          });
      };
      this.$refs.ruleForm.validateField("username", valid => {
        if (!valid) {
          checkphone();
        }
      });
    },
    // 切换
    accountLoginClick() {
      this.$refs.ruleForm.resetFields();
      this.$nextTick(() => {
        this.focusClear();
        this.$refs.ruleForm.resetFields();
        // this.ruleForm.password11 = '';
        // this.ruleForm.verifyprop = '';
        // this.ruleForm.username = '';
        this.accountLogin = !this.accountLogin;
        this.$refs.ruleForm.clearValidate();
      });
    },
    // 账号登录
    onSubmit() {
      // const urls = "local-deploy/is-force-update-pwd";
      // ajax.request("get", urls, {}).then(res => {
      //   if (res.code === 0) {
      //     console.log("res: ", res);
      //   }
      // });
      const that = this;
      // 登录时获取加密秘钥
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const res = await this.$api.getEncryption();
          that.encryption = res.data;
          if (this.accountLogin) {
            this.submits(1);
          } else {
            this.submits(0);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
        return false;
      });
      return false;
    },
    // 提交数据
    submits(params) {
      let urls = "";
      const objs = {};
      if (params) {
        // 手机验证码登录
        urls = "local-deploy/login/valid-sms-code";
        objs.mobile = this.ruleForm.username;
        objs.smsCode = this.ruleForm.verifyprop;
      } else {
        // 账号密码登录
        if (!this.encryption) {
          this.$notice.operateError(
            "登录失败",
            "获取秘钥失败，请联系管理员处理"
          );
          return;
        }
        const jse = new this.$jsEncrypt();
        let pass = this.ruleForm.password11;
        // 配置公钥
        jse.setPublicKey(this.encryption);
        // 加密密码
        pass = jse.encrypt(pass);
        urls = "local-deploy/login/password";
        objs.adminAccount = this.ruleForm.username;
        objs.password = pass;
        objs.encryption = this.encryption;
        objs.captcha = this.ruleForm.code;
        objs.captchaRandomCode = this.ruleForm.captchaRandomCode;
        // 世博园接口定制处理手机号加密
        if(window.scenicManagementPlatformConfig.projectName === 'shiyuan') {
          urls = "local-deploy/login/accountPasswordEncry";
          objs.adminAccount = jse.encrypt(this.ruleForm.username);
        }
      }
      ajax
        .request("post", urls, objs)
        .then(res => {
          this.loginSiteInfo(res);
          return false;
        })
        .catch(err => {
          this.errorTips = err.data.message;
          this.isLogin = true;
          this.isShake = true;
          // this.$notice.operateError('登录失败', err.data.message || '');
          this.getCaptcha();
          // this.ruleForm.password11 = '';
          this.ruleForm.code = "";
          return false;
        });
    },
    // 处理切换站点信息
    loginSiteInfo(res) {
      if (res.code === 0) {
        this.groups = res.data.groups || [];
        this.scenic = res.data.scenic || [];
        this.tempLicense = res.data.tempLicense || "";
        if (
          (this.groups && this.groups.length > 0) ||
          (this.scenic && this.scenic.length > 0)
        ) {
          if (this.groups.length + this.scenic.length === 1) {
            if (this.groups.length === 1) {
              this.changeSite(this.groups[0]);
            } else if (this.scenic.length === 1) {
              this.changeSite(this.scenic[0]);
            }
            this.changeCampany();
          } else {
            this.dialogVisible = true;
          }
        } else {
          this.$notice.operateSuccess("登录成功", "");
          window.location.href = res.data;
        }
        // window.location.href = res.data;
      } else {
        this.errorTips = res.message;
        this.isLogin = true;
        this.isShake = true;
        this.getCaptcha();
        this.ruleForm.code = "";
      }
    },
    // erter事件
    keyupEnter() {
      document.onkeydown = e => {
        if (e.keyCode === 13) {
          // match(此处应填写文件在浏览器中的地址，如 '/home/index')
          this.onSubmit();
        }
      };
    },

    /**
     * 倒计时跳转登录页
     * @param message
     */
    countDown(message) {
      // eslint-disable-next-line consistent-this
      const self = this;
      this.message = message;
      // 清空store
      this.$store.commit("clear");
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        self.seconds--;
        if (self.seconds === 0) {
          self.gotoLoginPage();
        }
      }, 1000);
    },
    gotoLoginPage() {
      top.location.href = window.scenicManagementPlatformConfig.entrance;
    },
    // 非本地部署
    login() {
      this.loading = true;
      if (this.authCode) {
        this.$api
          .login({
            authorizationCode: this.authCode
          })
          .then(res => {
            console.log("res: ", res);
            const data = res.data;
            if (data && data.token && data.token.token) {
              sessionStorage.setItem("homeMenu", "");
              sessionStorage.setItem("urlToken", data.token.token);
              this.$store.commit("user", data.profile);
              this.$store.commit("siteId", data.profile.umsCompany.id);
              window.scenicManagementPlatformConfig.entrance = `${location.protocol}//${data.refer}`;
              sessionStorage.setItem(
                "entrance",
                `${location.protocol}//${data.refer}`
              );
              if (data.expiredCode === "-100") {
                this.$router.push({
                  path: "/changePassword"
                });
                // 强制修改密码
              } else if (data.forceUpdatePwd) {
                this.forcePhone = data.profile.account.mobile;
                console.log(this.forcePhone, "ruleForm.username");

                this.isForceFassword = true;
              } else {
                // this.$store.commit('token', data.token.token);
                const type = this.$route.query.type;
                console.log("type: ", type);
                const thrid = this.$route.query.thrid;
                if (thrid) {
                  sessionStorage.setItem('isthridSys', 1);
                }
                // const flag = sessionStorage.getItem('iframe') || false;
                if (type) {
                  // 跳转到综合大屏页面
                  if (type === "index" || type === "guide") {
                    window.location.replace(
                      `${location.protocol}//${window.location.host}/scenic/${type}`
                    );
                  } else {
                    this.$router.push({
                      path: `/home?type=${type}`
                    });
                  }
                } else if (data.guidePageFlag && window.scenicManagementPlatformConfig.projectName !== 'shiyuan') {
                  // 跳转到导览页
                  this.$router.push({
                    path: "/guide"
                  });
                } else {
                  this.$router.push({
                    path: "/index"
                  });
                }
              }
            } else {
              this.loading = false;
              this.$notice.operateError("登录失败", "未获取到token");
            }
          })
          .catch(error => {
            console.log("error: ", error);
            this.loading = false;
            if (error.data) {
              this.$notice.operateError("登录失败", error.data.message);
              if (
                error.data.code === 20 ||
                error.data.message.include("授权码已过期")
              ) {
                // 20 authCode过期
                this.countDown("授权码已过期");
              }
            } else {
              // 判断http code
              this.$notice.operateError("登录失败", "后台异常");
              this.countDown("登录失败");
            }
          });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      if (to.query.authCode) {
        const param = decodeURIComponent(to.query.authCode);
        const authCode = param.split("?")[0];
        vm.authCode = authCode;
        vm.$store.commit("authCode", authCode);
      } else if (vm.code) {
        vm.authCode = vm.code;
      }
      if (window.scenicManagementPlatformConfig.isLocal) {
        // 本地部署
        vm.loading = false;
      } else {
        vm.login();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.timer && clearInterval(this.timer);
    next();
  }
};
</script>

<style lang="scss" scoped>
@include b(login) {
  height: 100%;

  .login-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-error {
      background-color: #fef0f0;
      border-color: #fde2e2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      margin-bottom: 10px;
    }

    .content {
      color: #f56c6c;
      font-size: 14px;
    }

    .el-icon-error {
      color: #f56c6c;
    }
  }

  .locallogigpage {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    position: relative;

    .imgs {
      width: 52%;
      height: auto;
      position: absolute;
      left: 7.5%;
      bottom: 97px;
      z-index: 1;
    }

    .formboxes {
      position: absolute;
      width: 20%;
      min-width: 400px;
      height: auto;
      top: 27.77vh;
      right: 13%;
      z-index: 10;
      font-family: 微软雅黑;

      h3 {
        font-size: 38px;
        line-height: 40px;
        letter-spacing: 3px;
        color: #ffffff;
        text-align: center;
        font-weight: 500;
        margin-bottom: 28px;
      }

      .ruleFormbox {
        width: 100%;
        padding: 0 12.5%;
        background-color: #ffffff;
        box-shadow: 0px 5px 30px 0px rgba(0, 17, 51, 0.13);
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        .wechart-imgbtn {
          width: 132px;
          height: 40px;
          background-image: url("../../assets/images/wecart-login.png");
          position: absolute;
          right: 10px;
          top: 10px;
          cursor: pointer;
        }
        .wechart-code {
          width: 100%;
          height: 470px;
          display: flex;
          justify-content: center;
          padding-top: 60px;
        }
      }

      .formtitles {
        padding: 49px 0 23px 0;
        width: 100%;
        text-align: center;
        color: #1b2440;
        line-height: 22px;
        font-size: 24px;
        font-family: "微软雅黑";
        // font-weight: 600;
      }

      .shake {
        animation: shake 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      }
      @keyframes shake {
        10%,
        90% {
          transform: translate3d(0, -1px, 0);
        }
        20%,
        80% {
          transform: translate3d(0, 2px, 0);
        }
        30%,
        50%,
        70% {
          transform: translate3d(0, -4px, 0);
        }
        40%,
        60% {
          transform: translate3d(0, 4px, 0);
        }
      }
      .loginbtns {
        width: 100%;
        height: 45px;
        background-color: #5e6cff;
        border-radius: 23px;
        letter-spacing: 9px;
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;
      }

      .otherstyle {
        padding-top: 6px;
        padding-bottom: 50px;
        color: #b0b3bf;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        .login-box {
          cursor: pointer;
          > i {
            margin-right: 5px;
          }
        }
        > span {
          margin-left: 8px;
          cursor: pointer;
          letter-spacing: 0.5px;
          user-select: none;
        }
      }
    }
  }
}
/deep/ .verifyitems .el-form-item__content {
  width: 100%;
  display: flex;
  line-height: 40px;
  justify-content: space-between;
  align-items: center;

  &::before {
    content: none;
    display: none;
  }

  .logininputsinner {
    width: calc(100% - 140px);
    /deep/.el-input__inner {
      -webkit-text-security: disc !important;
    }
  }

  .yzmtips {
    width: 120px;
    margin-left: 20px;
    font-size: 14px;
    text-align: center;
    color: #b0b3bf;
    border: 1px solid #e3e4e8;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    .colorboxs {
      color: #5e6cff;
    }
  }
}
/deep/ .logininputs {
  height: 46px;
  line-height: 46px;

  .el-input__inner {
    padding: 16px 0;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #e3e4e8;
    padding-left: 30px;
    box-shadow: inset 0 0 0 1000px #fff !important;
  }

  .daq-icon {
    color: #1b2440;
    font-size: 16px;
    // font-weight: 600;
  }
}
</style>

<style lang="scss">
@include b(login-dialog) {
  &.el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*height:600px;*/
    max-height: calc(100% - 200px);
    max-width: calc(100% - 30px);

    .dialogTitle {
      width: 100%;
      font-size: 16px;
      line-height: 28x;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      padding-bottom: 20px;
      color: #333333;

      .phone {
        color: #0492ff;
      }
    }

    .dialogInfo {
      width: 100%;
      max-height: 300px;
      overflow: auto;

      &-item {
        width: 100%;
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.1);
        background-color: #ffffff;
        border-radius: 8px;
        font-size: 18px;

        .info-box {
          display: flex;
          align-items: center;

          .header {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-color: rgba($color: #0492ff, $alpha: 0.2);
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 26px;
              color: #0492ff;
            }
          }

          .title {
            color: #333333;

            .mt10 {
              margin-top: 10px;
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:hover,
        &.active {
          border: 1px solid #0492ff;
          background-color: #0492ff;

          .info-box {
            .header {
              background-color: rgba($color: #ffffff, $alpha: 0.2);

              i {
                color: #ffffff;
              }
            }

            .title {
              color: #ffffff;
            }
          }
        }

        &.disable {
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          background-color: #ffffff;

          .info-box {
            .header {
              background-color: rgba($color: #bdbdbd, $alpha: 0.1);

              i {
                color: #cccccc;
              }
            }

            .title {
              color: #cccccc;
            }
          }
        }

        .disable-txt {
          color: #cccccc;
        }
      }
    }

    .dialogFooter {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .btn-info {
        width: 160px;
        height: 50px;
        background-color: #0492ff;
        font-size: 18px;
      }
    }
  }

  &.el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
  }
}

.locallogigpage {
  input:-internal-autofill-selected {
    background-color: #fff !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
    box-shadow: inset 0 0 0 1000px #fff !important;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset !important;
  }

  input:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #fff inset !important;
  }
}

.code {
  display: flex;
  justify-content: space-between;

  .inp {
    width: 190px;
  }

  .captcha {
    width: 100px;
    height: 40px;
    border: 1px solid $borderBase;
    border-radius: 4px;
    cursor: pointer;
  }
  .el-input__inner {
    height: 40px;
    border: 0 solid #dcdfe6;
    border-bottom: 1px solid #e3e4e8;
    border-color: #e3e4e8 !important;
  }
}
</style>
