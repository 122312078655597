<template>
  <el-dialog
    v-if="changepwd"
    title="初始密码修改"
    :visible.sync="changepwd"
    width="40%"
    custom-class="chanagepwdbox"
    :close-on-click-modal="false"
    modal-append-to-body
    :before-close="handleClose"
  >
    <div class="txt">基于系统安全与三级等保要求，请修改初始随机密码！</div>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="100px"
      class="ruleFormChPwd"
    >
      <el-form-item label="初始密码" prop="oldPassword">
        <el-input
          v-model.trim="ruleForm.oldPassword"
          maxlength="20"
          name="password"
          show-password
          autocomplete="new-password"
          class="logininputs"
          type="password"
          placeholder="请输入原密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newpassword">
        <el-input
          v-model.trim="ruleForm.newpassword"
          maxlength="20"
          name="password"
          show-password
          autocomplete="new-password"
          class="logininputs"
          type="password"
          placeholder="请输入新密码"
        ></el-input>
        <span class="tips">8-20位由大写字母+小写字母+数字+特殊符号组成！</span>
      </el-form-item>

      <el-form-item label="确认新密码" prop="surenewpassword">
        <el-input
          v-model.trim="ruleForm.surenewpassword"
          maxlength="20"
          class="logininputs"
          show-password
          name="password"
          autocomplete="new-password"
          type="password"
          placeholder="请确认新密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">
        取 消
      </el-button>
      <el-button type="primary" @click="sureChnagePwd">
        保 存
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ajax } from "@/utils";
export default {
  name: "ChangePassword",
  props: {
    changepwd: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: String,
      default: true
    }
  },
  data() {
    return {
      firstStep: false,
      timeNum: 60,
      timers: null,
      ruleForm: {
        oldPassword: "",
        phones: "",
        verifyprop: "",
        newpassword: "",
        surenewpassword: ""
      },
      rules: {
        oldPassword: [
          {
            required: true,
            trigger: "blur",
            message: "请输入原密码"
          }
        ],
        newpassword: [
          {
            required: true,
            trigger: "blur",
            validator: this.validatePassword
          }
        ],
        surenewpassword: [
          {
            required: true,
            message: "请输入确认新密码",
            trigger: "blur"
          },
          { min: 8, message: "密码最少8位", trigger: "blur" }
        ]
      },
      errorMsg: ""
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  created() {
    //  this.user.account.mobile ||
    this.ruleForm.phones = this.mobile;
    console.log(this.ruleForm.phones, "1322");
  },
  beforeDestroy() {
    if (this.timers) {
      window.clearInterval(this.timers);
    }
    this.timers = null;
    this.timeNum = 0;
  },
  methods: {
    validatePassword(rule, value, callback) {
      console.log("value: ", value);
      if (!value) {
        callback(new Error("请输入新密码"));
      } else {
        // const myreg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d!@$%&*]{8,20}/;
        const myreg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#.])[A-Za-z\d@$!%*?&#.]{8,20}$/;
        if (!myreg.test(value)) {
          callback(
            new Error("密码至少8-20位，包括大写+小写字母+数字+特殊符号组成")
          );
        } else {
          callback();
        }
      }
    },

    // 确定修改密码
    sureChnagePwd() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          if (
            this.ruleForm.newpassword &&
            this.ruleForm.newpassword !== this.ruleForm.surenewpassword
          ) {
            this.$notice.operateError("密码输入不一致", "请确认密码");
            return false;
          }
          const objs = {
            mobile: this.ruleForm.phones,
            password: this.ruleForm.newpassword,
            oldPassword: this.ruleForm.oldPassword
          };
          // 加密
          const res = await this.$api.getEncryption();
          const encryption = res.data;
          const jse = new this.$jsEncrypt();
          // 配置公钥
          jse.setPublicKey(encryption);
          // 加密密码
          objs.password = jse.encrypt(objs.password);
          objs.oldPassword = jse.encrypt(objs.oldPassword);
          objs.encryption = encryption;
          const urls = "local-deploy/update-password";
          ajax
            .request("put", urls, objs)
            .then(res => {
              if (res.code === 0) {
                this.$notice.operateSuccess("密码修改成功", res.message || "");
                // this.handleClose();
                // this.$store.commit('clear');
                this.handleClose();
                setTimeout(() => {
                  top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
                }, 500);
              } else {
                this.$notice.operateError("密码修改失败", res.message || "");
              }
              return false;
            })
            .catch(err => {
              this.$notice.operateError("密码修改失败", err.data.message || "");
              return false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
        return false;
      });
    },
    handleClose() {
      setTimeout(() => {
        this.$emit("closeDiag", false);
        top.location.href = `${window.location.protocol}//${window.location.host}/scenic/login`;
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
.txt {
  color: #999999;
  font-size: 12px;
  margin-bottom: 15px;
}
.chanagepwdbox {
  width: 500px;
}
.ruleFormChPwd {
  min-width: 300px;
  padding-right: 20px;
  .tips {
    font-size: 12px;
    color: #999999;
  }
  /deep/ .verifyitems .el-form-item__content {
    // width: 100%;
    display: flex;
    line-height: 40px;
    justify-content: space-between;
    align-items: center;
    &::before {
      content: none;
      display: none;
    }
    .logininputsinner {
      width: calc(100% - 140px);
    }
    .yzmtips {
      width: 120px;
      margin-left: 20px;
      font-size: 14px;
      text-align: center;
      color: #b0b3bf;
      border: 1px solid #e3e4e8;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      .colorboxs {
        color: #5e6cff;
      }
    }
  }
  /deep/ .logininputs {
    height: 42px;
    line-height: 42px;
    .el-input__inner {
      padding: 16px 0;
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      // border: none;
      // border-radius: 0px;
      box-sizing: border-box;
      border: 1px solid #e3e4e8;
      padding-left: 10px;
      box-shadow: inset 0 0 0 1000px #fff !important;
    }
    .daq-icon {
      color: #1b2440;
      font-size: 16px;
      // font-weight: 600;
    }
  }
  /deep/ .el-form-item {
    margin-bottom: 32px;
  }
}
/deep/ .el-button + .el-button {
  margin-left: 20px;
}
/deep/ .el-dialog__body {
  padding-bottom: 0px;
}
/deep/ .el-dialog__footer {
  padding-bottom: 30px;
}
</style>
