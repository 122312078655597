var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticClass: "cp-login",
      attrs: {
        "element-loading-text": "正在跳转，请稍后",
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "rgba(0, 0, 0, 0.8)",
      },
    },
    [
      _vm.localDeployment
        ? [
            _c(
              "div",
              {
                staticClass: "locallogigpage",
                style: {
                  "background-image": "url(" + _vm.loginBg.current + ")",
                },
              },
              [
                !_vm.isWgsLogin
                  ? _c("el-image", {
                      staticClass: "imgs",
                      attrs: { src: _vm.loginurl, fit: "cover" },
                    })
                  : _vm._e(),
                !_vm.dialogVisible
                  ? _c(
                      "div",
                      { staticClass: "formboxes" },
                      [
                        !_vm.bindFlag
                          ? _vm._l(
                              _vm.systemTitle.split("|"),
                              function (item, index) {
                                return _c(
                                  "h3",
                                  {
                                    key: "title_" + index,
                                    staticClass: "login-title",
                                  },
                                  [_vm._v(" " + _vm._s(item) + " ")]
                                )
                              }
                            )
                          : _vm._e(),
                        !_vm.bindFlag
                          ? _c(
                              "el-form",
                              {
                                ref: "ruleForm",
                                staticClass: "ruleFormbox",
                                attrs: {
                                  model: _vm.ruleForm,
                                  rules: _vm.rules,
                                  "label-width": "0px",
                                },
                              },
                              [
                                _vm.loginType
                                  ? [
                                      _c("div", {
                                        staticClass: "wechart-imgbtn",
                                        on: {
                                          click: function ($event) {
                                            _vm.loginType = 0
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "wechart-code" },
                                        [_c("cp-wechat-code")],
                                        1
                                      ),
                                    ]
                                  : _vm.loginType === 0 && !_vm.wxAccreditKey
                                  ? [
                                      _c("div", { staticClass: "formtitles" }, [
                                        _vm._v(" 用户登录 "),
                                      ]),
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "",
                                            prop: "username",
                                            error: _vm.errorMsg,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              staticClass: "logininputs",
                                              attrs: {
                                                maxlength: "11",
                                                type: "text",
                                                readonly: _vm.readonly,
                                                "auto-complete": "off",
                                                placeholder: "请输入账号",
                                              },
                                              on: {
                                                focus: _vm.accountFocus,
                                                blur: _vm.checkPhone,
                                                input: function ($event) {
                                                  _vm.ruleForm.username =
                                                    _vm.ruleForm.username.replace(
                                                      /[^\d]/g,
                                                      ""
                                                    )
                                                },
                                              },
                                              model: {
                                                value: _vm.ruleForm.username,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleForm,
                                                    "username",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "ruleForm.username",
                                              },
                                            },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "daq-icon cp-icon",
                                                  attrs: { slot: "prefix" },
                                                  slot: "prefix",
                                                },
                                                [_vm._v("")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      !_vm.accountLogin
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "",
                                                prop: "password11",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass: "logininputs",
                                                  attrs: {
                                                    maxlength: "20",
                                                    "auto-complete":
                                                      "new-password",
                                                    type: "password",
                                                    readonly: _vm.readonly,
                                                    placeholder: "请输入密码",
                                                  },
                                                  on: {
                                                    focus: _vm.accountFocus,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm.password11,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm,
                                                        "password11",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm.password11",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "daq-icon cp-icon",
                                                      attrs: { slot: "prefix" },
                                                      slot: "prefix",
                                                    },
                                                    [_vm._v("")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.accountLogin
                                        ? _c("div", { staticClass: "code" }, [
                                            _c(
                                              "div",
                                              { staticClass: "inp" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  { attrs: { prop: "code" } },
                                                  [
                                                    _c(
                                                      "el-input",
                                                      {
                                                        staticStyle: {
                                                          height: "40px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          maxlength: "4",
                                                          placeholder:
                                                            "请输入验证码",
                                                          autocomplete: "off",
                                                        },
                                                        on: {
                                                          focus: _vm.focusClear,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleForm.code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleForm,
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleForm.code",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "cp-icon",
                                                            attrs: {
                                                              slot: "prefix",
                                                            },
                                                            slot: "prefix",
                                                          },
                                                          [_vm._v("  ")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c("img", {
                                              staticClass: "captcha",
                                              attrs: {
                                                src: _vm.captcha,
                                                alt: "验证码",
                                              },
                                              on: { click: _vm.getCaptcha },
                                            }),
                                          ])
                                        : _vm._e(),
                                      _vm.accountLogin
                                        ? _c(
                                            "el-form-item",
                                            {
                                              staticClass: "verifyitems",
                                              attrs: {
                                                label: "",
                                                prop: "verifyprop",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass:
                                                    "logininputs logininputsinner",
                                                  attrs: {
                                                    maxlength: "6",
                                                    placeholder: "请输入验证码",
                                                    readonly: _vm.readonly,
                                                  },
                                                  on: {
                                                    focus: _vm.accountFocus,
                                                    input: function ($event) {
                                                      _vm.ruleForm.verifyprop =
                                                        _vm.ruleForm.verifyprop.replace(
                                                          /[^\d]/g,
                                                          ""
                                                        )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm.verifyprop,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm,
                                                        "verifyprop",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm.verifyprop",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "daq-icon cp-icon",
                                                      attrs: { slot: "prefix" },
                                                      slot: "prefix",
                                                    },
                                                    [_vm._v("")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "yzmtips" },
                                                [
                                                  _vm.timeNum === 60 ||
                                                  _vm.timeNum <= 0
                                                    ? _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click:
                                                              _vm.clickTime,
                                                          },
                                                        },
                                                        [_vm._v(" 短信验证码 ")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "colorboxs",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.timeNum
                                                            ) + " s"
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "loginbtns",
                                              class: { shake: _vm.isShake },
                                              attrs: { type: "primary" },
                                              on: { click: _vm.onSubmit },
                                            },
                                            [_vm._v(" 登录 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isLogin
                                        ? _c("el-alert", {
                                            attrs: {
                                              title: _vm.errorTips,
                                              type: "error",
                                              closable: false,
                                              "show-icon": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("div", { staticClass: "otherstyle" }, [
                                        _c(
                                          "div",
                                          { staticClass: "login-box" },
                                          [
                                            _c(
                                              "i",
                                              {
                                                staticClass: "daq-icon cp-icon",
                                              },
                                              [_vm._v("")]
                                            ),
                                            _vm.accountLogin
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.accountLoginClick(
                                                          false
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("账号密码登录")]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.accountLoginClick(
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("短信验证码登录")]
                                                ),
                                          ]
                                        ),
                                        _vm.wxAccreditLoginFlag
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "login-box",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.loginType = 1
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "daq-icon cp-icon",
                                                  },
                                                  [_vm._v("")]
                                                ),
                                                _c("span", [
                                                  _vm._v("微信扫码登录"),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        : _vm._e(),
      false
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.loading,
                    expression: "!loading",
                  },
                ],
                staticClass: "login-info",
              },
              [
                _c("div", { staticClass: "message-error" }, [
                  _c("i", { staticClass: "el-message__icon el-icon-error" }),
                  _c("p", { staticClass: "content" }, [
                    _vm._v(" " + _vm._s(_vm.message) + "，"),
                    _c("span", [_vm._v(_vm._s(_vm.seconds))]),
                    _vm._v("秒后将跳转登录页 "),
                  ]),
                ]),
                _c("el-button", { on: { click: _vm.gotoLoginPage } }, [
                  _vm._v(" 立即跳转 "),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.isForceFassword
        ? _c("cp-force-password", {
            attrs: { changepwd: _vm.isForceFassword, mobile: _vm.forcePhone },
            on: {
              closeDiag: function ($event) {
                _vm.isForceFassword = false
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            modal: true,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "show-close": false,
            "custom-class": "cp-login-dialog",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.dialogVisible
            ? _c(
                "div",
                {
                  staticClass: "dialogTitle",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _vm._v(" 检测到"),
                  _c("font", { staticClass: "phone" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.mobileFilter(_vm.ruleForm.username)) +
                        " "
                    ),
                  ]),
                  _vm._v("在系统内存在多个身份，请选取一个身份进行登录 "),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogVisible
            ? _c(
                "div",
                { staticClass: "dialogInfo" },
                [
                  _vm._l(_vm.groups, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "dialogInfo-item",
                        class: {
                          disable: item.status === 0,
                          active:
                            _vm.site.accountId === item.accountId &&
                            _vm.site.id === item.id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeSite(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "info-box" }, [
                          _c("div", { staticClass: "header" }, [
                            _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _c("p", [_vm._v(_vm._s(item.companyName))]),
                            _vm.groupNum > 1
                              ? _c("p", [
                                  _vm._v(" " + _vm._s(item.empName) + " "),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        item.status === 0
                          ? _c("div", { staticClass: "disable-txt" }, [
                              _vm._v(" 禁用 "),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  _vm._l(_vm.scenic, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "dialogInfo-item",
                        class: {
                          disable: item.status === 0,
                          active:
                            _vm.site.accountId === item.accountId &&
                            _vm.site.id === item.id,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.changeSite(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "info-box" }, [
                          _c("div", { staticClass: "header" }, [
                            _c("i", { staticClass: "cp-icon" }, [_vm._v("")]),
                          ]),
                          _c("div", { staticClass: "title" }, [
                            _c("p", [_vm._v(_vm._s(item.scenicName))]),
                            _vm.companyNum > 1
                              ? _c("p", [
                                  _vm._v(" " + _vm._s(item.empName) + " "),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        item.status === 0
                          ? _c("div", { staticClass: "disable-txt" }, [
                              _vm._v(" 禁用 "),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "dialogFooter" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-info",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.changeCampany()
                    },
                  },
                },
                [_vm._v(" 登录 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.bindFlag,
            modal: true,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
            "show-close": false,
            "custom-class": "cp-login-dialog",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bindFlag = $event
            },
          },
        },
        [
          _vm.bindFlag
            ? _c(
                "div",
                {
                  staticClass: "dialogTitle",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(" 当前微信用户未绑定手机号，请输入系统登录手机号绑定 ")]
              )
            : _vm._e(),
          _vm.bindFlag
            ? _c(
                "div",
                { staticClass: "dialogInfo locallogigpage" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "ruleFormbox",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "0px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            prop: "username",
                            error: _vm.errorMsg,
                          },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "logininputs",
                              attrs: {
                                maxlength: "11",
                                type: "text",
                                size: "large",
                                readonly: _vm.readonly,
                                "auto-complete": "off",
                                placeholder: "请输入账号",
                              },
                              on: {
                                focus: _vm.accountFocus,
                                blur: _vm.checkPhone,
                                input: function ($event) {
                                  _vm.ruleForm.username =
                                    _vm.ruleForm.username.replace(/[^\d]/g, "")
                                },
                              },
                              model: {
                                value: _vm.ruleForm.username,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "username",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.username",
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "daq-icon cp-icon",
                                  attrs: { slot: "prefix" },
                                  slot: "prefix",
                                },
                                [_vm._v("")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "verifyitems",
                          attrs: { label: "", prop: "verifyprop" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "logininputs logininputsinner",
                              attrs: {
                                maxlength: "6",
                                size: "large",
                                placeholder: "请输入验证码",
                                readonly: _vm.readonly,
                              },
                              on: {
                                focus: _vm.accountFocus,
                                input: function ($event) {
                                  _vm.ruleForm.verifyprop =
                                    _vm.ruleForm.verifyprop.replace(
                                      /[^\d]/g,
                                      ""
                                    )
                                },
                              },
                              model: {
                                value: _vm.ruleForm.verifyprop,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "verifyprop",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "ruleForm.verifyprop",
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "daq-icon cp-icon",
                                  attrs: { slot: "prefix" },
                                  slot: "prefix",
                                },
                                [_vm._v("")]
                              ),
                            ]
                          ),
                          _c("p", { staticClass: "yzmtips" }, [
                            _vm.timeNum === 60 || _vm.timeNum <= 0
                              ? _c("span", { on: { click: _vm.clickTime } }, [
                                  _vm._v(" 短信验证码 "),
                                ])
                              : _c("span", { staticClass: "colorboxs" }, [
                                  _vm._v(_vm._s(_vm.timeNum) + " s"),
                                ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "dialogFooter" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-info",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.bindWxUserName()
                    },
                  },
                },
                [_vm._v(" 立即绑定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }