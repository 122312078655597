<template>
  <div class="cp-wechat-code" id="login_container">
    <div v-if="wechatUrl" class="qr-box">
      <div class="qr-title">扫码登录</div>
      <vue-qr :text="wechatUrl" :size="300"></vue-qr>
      <div class="qr-foot">请使用微信扫一扫登录</div>
    </div>
  </div>
</template>

<script>
import { ajax } from "@/utils";
import vueQr from 'vue-qr'
import scenicManagementPlatformConfig from 'scenicManagementPlatformConfig';
export default {
  name: 'WechatCode',
  props: {},
  components: {
    vueQr
  },
  data() {
    return {
      wechatUrl: ''
    };
  },
  mounted(){
    if(scenicManagementPlatformConfig.wechatUrl){
      this.wechatUrl = scenicManagementPlatformConfig.wechatUrl;
    }else{
      this.createParam();
    }
  },
  methods: {
    // 生成二维码
    initWebChatCode(data){
      var obj = new WxLogin({
        self_redirect:true,
        id:"login_container", 
        appid: data.appId, 
        scope: data.scope, 
        redirect_uri: data.redirectUri,
        state: data.state,
        style: "",
        href: ""
      });
    },
    // 获取系统标题
    createParam() {
      const urls = "local-deploy/login/wx-qr/create-param";
      ajax.request("get", urls, {}).then(res => {
        if (res.code === 0) {
          this.initWebChatCode(res.data);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.cp-wechat-code{
  width: 300px;
  height: 400px;
}
.qr-box{
  position: relative;
  padding-top: 30px;
  .qr-title{
    font-size: 18px;
    position: absolute;
    top: -40px;
    left: -20px;
  }
  .qr-foot{
    width: 100%;
    color: #999999;
    text-align: center;
  }
}
</style>
