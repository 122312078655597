var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cp-wechat-code", attrs: { id: "login_container" } },
    [
      _vm.wechatUrl
        ? _c(
            "div",
            { staticClass: "qr-box" },
            [
              _c("div", { staticClass: "qr-title" }, [_vm._v("扫码登录")]),
              _c("vue-qr", { attrs: { text: _vm.wechatUrl, size: 300 } }),
              _c("div", { staticClass: "qr-foot" }, [
                _vm._v("请使用微信扫一扫登录"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }